import { IInitialisable } from "../../interfaces/IInitialisable";
import { isChromeVersion } from "../../utils/isChromeVersion";

export class HtmlImportTagHelper implements IInitialisable {
	private attributeName = "data-jscmpt-html-import";
	private escapeAttributeName = "data-escape-response";
	private replaceAttributeName = "data-replace-parent";
	private documentHelper = document;
	private allowedRequestPrefixes = ["http://localhost:", "http://127.0.0.1:", "/", "./", "../", "#{ComponentLibraryConfiguration:DomainUrl}"];

	private isChromeVersion = isChromeVersion;

	// Tested
	public Init() {
		this.documentHelper.querySelectorAll<HTMLElement>(`[${this.attributeName}]`).forEach((el) => this.fetchImport(el));
		this.setViewBox()

	}

	// Tested
	private async fetchImport(targetEl: HTMLElement) {
		const url = targetEl.getAttribute(this.attributeName) || "";
		const isUrlAllowed = this.allowedRequestPrefixes.some((x) => url.startsWith(x));
		if (isUrlAllowed === false) {
			throw new Error(`Forbidden: '${url}' does not start with '${this.allowedRequestPrefixes.join(" or ")}'`);
		}

		const response = await fetch(url);
		const responseBody = response.ok ? await response.text() : "";

		let htmlToInject = "";
		if (targetEl.hasAttribute(this.escapeAttributeName)) {
			htmlToInject = this.escape(responseBody);
		} else {
			htmlToInject = this.sanatise(responseBody);
		}

		if (targetEl.hasAttribute(this.replaceAttributeName)) {
			targetEl.outerHTML = htmlToInject;
		} else {
			targetEl.innerHTML = htmlToInject;
		}

		targetEl.dispatchEvent(new CustomEvent("load"));
	}

	// Tested
	private escape(html: string) {
		const temp = document.createElement("div");
		temp.textContent = html;
		return temp.innerHTML;
	}

	// Tested
	private sanatise(html: string) {
		const blacklistedTags = ["audio", "embed", "iframe", "link", "object", "script", "source", "style", "video"];
		blacklistedTags.forEach((tagName) => {
			const standardTagPattern = new RegExp(`<\\s*${tagName}[^>]*\\s*>(.*?)<\\s*/\\s*${tagName}\\s*>`, "ig");
			const selfClosingTagPattern = new RegExp(`<\\s*${tagName}[^/]*/\\s*>`, "ig");
			const noClosingTagPattern = new RegExp(`<\\s*${tagName}[^>]*\\s*>`, "ig");
			let match;
			while ((match = standardTagPattern.exec(html)) !== null) {
				html = html.replace(match[0], this.escape(match[0]));
			}
			while ((match = selfClosingTagPattern.exec(html)) !== null) {
				html = html.replace(match[0], this.escape(match[0]));
			}
			while ((match = noClosingTagPattern.exec(html)) !== null) {
				html = html.replace(match[0], this.escape(match[0]));
			}
		});

		return html.replace(/(\s+)(on[a-z]+)=("|')(.*?)("|')/gi, "");
	}

	// Tested
	private setViewBox() {
		if (this.isChromeVersion(103)) {
			this.documentHelper.querySelectorAll<HTMLElement>(`svg > use[*|href^="#"]`).forEach((el) => el.parentElement?.setAttribute('viewBox', '0 0 104 104'));
		}
	}
}
