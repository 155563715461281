// Tested
export const documentReady = (timeoutMs = 10000, doc = document) => {
	return new Promise<void>((res, rej) => {
		const timeout = setTimeout(() => rej(`Stopped waiting for Document ready after ${timeoutMs}ms`), timeoutMs);
		const cancelTimeoutAndResolve = () => { clearTimeout(timeout); res(); };
		if (typeof doc.readyState === 'string' && doc.readyState !== 'loading') {
			cancelTimeoutAndResolve();
		} else {
			doc.addEventListener('DOMContentLoaded', cancelTimeoutAndResolve);
		}
	});
};
